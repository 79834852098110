import * as React from "react"
import { graphql } from "gatsby"
import { Container, Section, HomepageImage, Heading } from "./ui"
import * as styles from "../templates/blog-post.css"

export interface AboutHeroProps {
  heading: string
  body: any
  text?: string
  image?: HomepageImage
}

export default function AboutHero(props: AboutHeroProps) {
  console.log("i mount props", props)
  return (
    <Section>
      <Container>
        <Heading as="h1" center>
          {props.heading}
        </Heading>
        {props.body && (
          <div
            className={styles.blogPost}
            dangerouslySetInnerHTML={{
              __html: props.body,
            }}
          />
        )}
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment AboutHeroContent on AboutHero {
    id
    heading
    body
    text
    image {
      id
      gatsbyImageData
      alt
    }
  }
`
